import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/LoginPage';
import UserManagement from './components/UserManagement';
import PersonalDjp from './components/PersonalDjp';
import CreateTipUser from './components/CreateTipUser';
import CreateHboUser from './components/CreateHboUser';
import DjpTip from './components/DjpTip';
import DjpHbo from './components/DjpHbo';
import Homepage from './components/Homepage';
import UpdateLocation from './components/UpdateLocation';
import EditRequestLocation from './components/EditRequestLocation';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    username: '',
    nama_lengkap: '',
    area_flag: '',
    area_name: '',
    regional: '',
    branch: '',
    cluster_name: '',
    level: '',
    flag_user: '',
    created_flag: '',
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    const storedNamaLengkap = sessionStorage.getItem("nama_lengkap");
    const storedAreaFlag = sessionStorage.getItem("area_flag");
    const storedAreaName = sessionStorage.getItem("area_name");
    const storedRegional = sessionStorage.getItem("regional");
    const storedBranch = sessionStorage.getItem("branch");
    const storedClusterName = sessionStorage.getItem("cluster_name");
    const storedLevel = sessionStorage.getItem("level");
    const storedFlagUser = sessionStorage.getItem("flag_user");
    const storedCreatedFlag = sessionStorage.getItem("created_flag");
    
    if (storedUsername) {
      setLoggedIn(true);
      setUserData({
        username: storedUsername,
        nama_lengkap: storedNamaLengkap,
        area_flag: storedAreaFlag,
        area_name: storedAreaName,
        regional: storedRegional,
        branch: storedBranch,
        cluster_name: storedClusterName,
        level: storedLevel,
        flag_user: storedFlagUser,
        created_flag: storedCreatedFlag,
      });
    } else {
      setLoggedIn(false);
    }
  }, []);

  
  return (
    
    <BrowserRouter>
      {loggedIn ? (
        <div className={`wrapper ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          <Sidebar isOpen={isSidebarOpen} level={userData.level} created_flag={userData.created_flag} />
          <div className="main">
            <Header toggleSidebar={toggleSidebar} username={userData.username} />
            <Routes>
              {(userData.level === "0" || userData.level === "1" || userData.level === "2" || userData.level === "5") && userData.created_flag === "1" ? (
                <>                  
                  <Route 
                    path="/createUserInternal" 
                    element={
                      <UserManagement 
                        username={userData.username} 
                        level={userData.level} 
                      />
                    } 
                  />
                </>
              ) : null}

              {userData.level === "3" && userData.created_flag === "1" ? (
                <>                  
                  <Route 
                    path="/tipDjp" 
                    element={
                      <DjpTip 
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        region={userData.regional} 
                        branch={userData.branch} 
                        cluster_name={userData.cluster_name} 
                        level={userData.level} 
                      />
                    } 
                  />
                  
                  <Route 
                    path="/createUserTip" 
                    element={
                      <CreateTipUser 
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        area_name={userData.area_name} 
                        region={userData.regional} 
                        branch={userData.branch} 
                      />
                    } 
                  />
                </>
              ) : null}

              {userData.level === "4" && userData.created_flag === "1" ? (
                <>              
                  <Route 
                    path="/hboDjp" 
                    element={
                      <DjpHbo 
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        region={userData.regional} 
                        branch={userData.branch} 
                        cluster_name={userData.cluster_name} 
                        level={userData.level} 
                      />
                    } 
                  />
                  
                  <Route 
                    path="/createUserHbo" 
                    element={
                      <CreateHboUser 
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        area_name={userData.area_name} 
                        region={userData.regional} 
                        branch={userData.branch} 
                        cluster_name={userData.cluster_name} 
                      />
                    } 
                  />
                </>
              ) : null}

              {["0", "3", "4"].includes(userData.level) && userData.created_flag === "1" ? (
                <>
                  <Route 
                    path="/updateLocation" 
                    element={
                      <UpdateLocation
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        region={userData.regional} 
                        branch={userData.branch} 
                        cluster_name={userData.cluster_name} 
                        level={userData.level} 
                      />
                    } 
                  />

                </>
              ) : null}

              {["2"].includes(userData.level) && userData.created_flag === "1" ? (
                <>
                  <Route 
                    path="/requestLocation" 
                    element={
                      <EditRequestLocation
                        username={userData.username} 
                        area_flag={userData.area_flag} 
                        region={userData.regional} 
                        branch={userData.branch} 
                        cluster_name={userData.cluster_name} 
                        level={userData.level} 
                      />
                    } 
                  />

                </>
              ) : null}
              
              
              <Route 
                path="/"
                element={
                  <Homepage 
                    namalengkap={userData.nama_lengkap} 
                    username={userData.username} 
                    level={userData.level} 
                    created_flag={userData.created_flag}
                    areaflag={userData.area_flag} 
                    area={userData.area_name} 
                    regional={userData.regional} 
                    branch={userData.branch} 
                    cluster={userData.cluster_name} 
                  />
                } 
              />

              <Route 
                path="/personalDjp" 
                element={
                  <PersonalDjp 
                    username={userData.username} 
                    area_flag={userData.area_flag} 
                    region={userData.regional} 
                    branch={userData.branch} 
                    cluster_name={userData.cluster_name} 
                    level={userData.level} 
                  />
                } 
              />
            </Routes>
            <Footer />
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage username={userData.username} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Row, Col, Container, Table, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { FaSort } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <span>
            Search: {' '}
            <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Type to search..."
                style={{ fontSize: '12px', marginBottom: '10px' }}
            />
        </span>
    );
};

const HomepageDjpTerritoryRegion = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);        
        axios.get(`${process.env.REACT_APP_SERVER}/data-performance-checkin-region`)
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoading(false);
            });
    }, []);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            { Header: 'AREA', accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'REGIONAL', accessor: 'regional', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'TOTAL USER', accessor: 'total_user', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: 'USER ACTIVE', accessor: 'user_active', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: '% ACTIVE', accessor: 'persen_active', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}%</div> },
            { Header: 'USER PRODUCTIVE', accessor: 'user_productive', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: '% PRODUCTIVE', accessor: 'persen_productive', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}%</div> },
            { Header: 'MTD DJP', accessor: 'mtd_djp', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: 'MTD CHECKIN', accessor: 'mtd_checkin', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: 'ACH', accessor: 'mtd_ach', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}%</div> },
            { Header: 'BUKA', accessor: 'success_visit', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },            
        ],[]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { globalFilter },
        setGlobalFilter, // Tambahkan setGlobalFilter
        rows,
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useGlobalFilter, // Tambahkan useGlobalFilter
        useSortBy
    );

    const filteredTotals = useMemo(() => {
        return rows.reduce(
          (totals, row) => {
            return {
                total_user: totals.total_user + row.original.total_user,
                user_active: totals.user_active + row.original.user_active,
                user_productive: totals.user_productive + row.original.user_productive,
                mtd_djp: totals.mtd_djp + row.original.mtd_djp,
                mtd_checkin: totals.mtd_checkin + row.original.mtd_checkin,
                success_visit: totals.success_visit + row.original.success_visit,
            };
          },
          { total_user: 0, user_active: 0, user_productive: 0, mtd_djp: 0, mtd_checkin: 0, success_visit : 0  }
        );
      }, [rows]);

    return (
        <div>
            {/* Table Container */}
            <Container fluid className="my-1">
                <Row>
                    <Col>
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="float-left">&nbsp;</h5>
                                    <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} className="float-right" />
                                </div>
                                
                                <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '11px', width: '100%' }} >
                                    <thead className="custom-thead text-center">
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                    >
                                                        {column.render('Header')}
                                                        <span className="sort-arrow"> <FaSort style={{ color: '#DDD' }}/></span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map(row => { // Use rows instead of page
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()} className="py-1 my-0">{cell.render('Cell')}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-secondary text-white" style={{ fontSize: '12px', fontWeight:'bold'}}>
                                            <td colSpan="2" className="text-center p-1">TOTAL</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.total_user)}</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.user_active)}</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>
                                                {filteredTotals.total_user > 0 
                                                    ? `${((filteredTotals.user_active / filteredTotals.total_user) * 100).toFixed(2)}%` : '0%'
                                                }
                                            </td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.user_productive)}</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>
                                                {filteredTotals.total_user > 0 
                                                    ? `${((filteredTotals.user_productive / filteredTotals.total_user) * 100).toFixed(2)}%` : '0%'
                                                }
                                            </td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.mtd_djp)}</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.mtd_checkin)}</td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>
                                                {filteredTotals.total_user > 0 
                                                    ? `${((filteredTotals.mtd_checkin / filteredTotals.mtd_djp) * 100).toFixed(2)}%` : '0%'
                                                }
                                            </td>
                                            <td className="p-1" style={{ textAlign: 'right' }}>{formatNumber(filteredTotals.success_visit)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>            
        </div>
    );
};

export default HomepageDjpTerritoryRegion;

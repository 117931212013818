import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegCalendarCheck, FaUser, FaRegClock } from "react-icons/fa";
import HomepageChart from './HomepageChart';

const HomepageCardHeader = () => {
    const [dashboardData, setDashboardData] = useState({
        user_ext: 0,
        ach_user_ext: 0,
        user_int: 0,
        ach_user_int: 0,
        trx_checkin: 0,
        mom_checkin: 0,
        trx_djp: 0,
        mom_djp: 0,
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/card-homepage-total`)
            .then(response => {
                const data = response.data[0]; // Assuming single row result
                setDashboardData({
                    user_ext: data.user_ext,
                    ach_user_ext: data.ach_user_ext,
                    user_int: data.user_int,
                    ach_user_int: data.ach_user_int,
                    trx_checkin: data.trx_checkin,
                    mom_checkin: data.mom_checkin,
                    trx_djp: data.trx_djp,
                    mom_djp: data.mom_djp,
                });
            })
            .catch(error => {
                console.error('Error fetching dashboard data:', error);
            });
    }, []);

    return (
        <>
            <div className="col-xl-6 col-xxl-5 d-flex">
                <div className="w-100">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mt-0">
                                            <h5 className="card-title">EXTERNAL USERS</h5>
                                        </div>
                                        <div className="col-auto">
                                            <div className="rounded-circle p-1 d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px', backgroundColor:'#EEE' }}>
                                                <FaUser className="text-dark" />
                                            </div> 
                                        </div>
                                    </div>
                                    <h1 className="mt-1 mb-3">{dashboardData.user_ext ? dashboardData.user_ext.toLocaleString() : 0}</h1>
                                    <div className="mb-0 text-sm">
                                        <span className="bg-danger px-2 py-1 rounded text-white"> 
                                            {dashboardData.ach_user_ext ? dashboardData.ach_user_ext.toLocaleString() : 0}%
                                        </span> 
                                        <span> Active This Month</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mt-0">
                                            <h5 className="card-title">INTERNAL USERS</h5>
                                        </div>
                                        <div className="col-auto">
                                            <div className="rounded-circle p-1 d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px', backgroundColor:'#EEE' }}>
                                                <FaUser className="text-dark" />
                                            </div> 
                                        </div>
                                    </div>
                                    <h1 className="mt-1 mb-3">{dashboardData.user_int ? dashboardData.user_int.toLocaleString() : 0}</h1>
                                    <div className="mb-0 text-sm">
                                        <span className="bg-danger px-2 py-1 rounded text-white"> 
                                            {dashboardData.ach_user_int ? dashboardData.ach_user_int.toLocaleString() : 0}%
                                        </span> 
                                        <span> Active This Month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mt-0">
                                            <h5 className="card-title">MTD CHECKIN</h5>
                                        </div>
                                        <div className="col-auto">
                                            <div className="rounded-circle p-1 d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px', backgroundColor:'#EEE' }}>
                                                <FaRegCalendarCheck  className="text-dark" />
                                            </div> 
                                        </div>
                                    </div>
                                    <h1 className="mt-1 mb-3">{dashboardData.trx_checkin ? dashboardData.trx_checkin.toLocaleString() : 0}</h1>
                                    <div className="mb-0 text-sm">
                                        <span className="bg-danger px-2 py-1 rounded text-white"> 
                                            {dashboardData.mom_checkin ? dashboardData.mom_checkin.toLocaleString() : 0}%
                                        </span> 
                                        <span> Monthly Growth</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mt-0">
                                            <h5 className="card-title">MTD DJP</h5>
                                        </div>
                                        <div className="col-auto">
                                            <div className="rounded-circle p-1 d-flex justify-content-center align-items-center" style={{ width: '40px', height: '40px', backgroundColor:'#EEE' }}>
                                                <FaRegClock className="text-dark" />
                                            </div>                                                                                            
                                        </div>
                                    </div>
                                    <h1 className="mt-1 mb-3">{dashboardData.trx_djp ? dashboardData.trx_djp.toLocaleString() : 0}</h1>
                                    <div className="mb-0 text-sm">
                                        <span className="bg-danger px-2 py-1 rounded text-white"> 
                                            {dashboardData.mom_djp ? dashboardData.mom_djp.toLocaleString() : 0}%
                                        </span> 
                                        <span> Monthly Growth</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-6 col-xxl-7">
                <div className="card flex-fill w-100">
                    <div className="card-header">
                        <h5 className="card-title my-2">CHECK-IN OUTLET TO DJP SCHEDULE - BULAN INI</h5>
                    </div>
                    <div className="card-body pt-2 pb-3">
                        <div className="chart chart-sm mb-2">
                            <HomepageChart />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomepageCardHeader
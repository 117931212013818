import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';

const HomepageChart = () => {
  const [chartData, setChartData] = useState({ series: [], categories: [] });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}/chart-homepage-total`)
      .then(response => {
        const data = response.data;
        console.log('Data from server:', data);

        const series = [
          { name: 'CHECKIN', data: [] },
          { name: 'NO CHECKIN', data: [] }
        ];
        const categories = [];

        data.forEach(row => {
          categories.push(row.event_date);  // Use event_date directly for x-axis
          series[0].data.push(row.checkin);
          series[1].data.push(row.no_checkin);
        });

        console.log('Categories:', categories);
        console.log('Series:', series);

        setChartData({ series, categories });
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
      });
  }, []);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 1,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          total: {
            enabled: true
          }
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: chartData.categories,  // Use the event_date values for x-axis labels
      labels: {
        formatter: function(value) {
          const date = new Date(value);
          //const year = date.getFullYear();
          //const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
          const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
          return `${day}`;
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        show: false // Hide numerical labels
      },
      axisBorder: {
        show: false // Hide axis border
      },
      axisTicks: {
        show: false // Hide axis ticks
      },
      lines: {
        show: false // Hide grid lines
      }
    },
    grid: {
      show: false // Hide all grid lines
    },
    legend: {
      position: 'bottom',
      offsetY: 10
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: false,
        gradientToColors: undefined,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [0, 100]
      }
    },
    colors: ['#32a88f', '#FF0000'],
    
  };

  return (
    <div id="chart">
      <ApexCharts options={options} series={chartData.series} type="bar" height={245} />
    </div>
  );
}

export default HomepageChart;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Container, Table, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaSort } from "react-icons/fa";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <span>
            Search: {' '}
            <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Type to search..."
                style={{ fontSize: '12px', marginBottom: '10px' }}
            />
        </span>
    );
};

const HomepageReportCheckinDjpToday = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);  

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_SERVER}/data-report-checkin-djp-external-today`)
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoading(false);
            });
    }, []);

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            { Header: () => <div style={{ textAlign: 'center' }}>NO</div>, accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>USERNAME</div>, accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>NAMA</div>, accessor: 'nama_lengkap', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>AREA</div>, accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>REGIONAL </div>, accessor: 'regional', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>BRANCH </div>, accessor: 'branch', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>CLUSTER</div>, accessor: 'cluster_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>FLAG</div>, accessor: 'flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>BY</div>, accessor: 'created_by', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>DJP</div>, accessor: 'outlet_djp', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>ACT</div>, accessor: 'total_checkin', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>GAP</div>, accessor: 'gap', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{`${value}`}</div> },
            { Header: () => <div style={{ textAlign: 'center' }}>BUKA</div>, accessor: 'success_visit', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'right' }}>{value}</div> },
        ], []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div>            

            <Container fluid className="my-1">
                <Row>
                    <Col>
                        
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h6 className="float-left">TODAY PERIOD</h6>
                                    <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} className="float-right" />
                                </div>
                                <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '11px', width: '100%' }} width="100%">
                                    <thead className="custom-thead text-center">
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                    >
                                                        <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {column.render('Header')}
                                                            <FaSort style={{ color: '#DDD', marginLeft: '5px' }} />
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()} className="py-1">{cell.render('Cell')}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </>
                            
                        )}
                    </Col>
                </Row>

                <Row className="d-flex justify-content-between my-2 mx-2">
                    <Col xs={8}>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>First</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>Previous</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>Next</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>Last</button>
                        <span className="mx-2">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end">
                        <select
                            className="form-select"
                            value={pageSize}
                            onChange={e => setPageSize(Number(e.target.value))}
                            style={{ width: '80px', fontSize: '12px' }}
                        >
                            {[5, 10, 20, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomepageReportCheckinDjpToday;

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, Container, Form, Button, ListGroup, Table, Modal, Spinner } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { FaTrash } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <span>
            Search: {' '}
            <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Type to search..."
                style={{ fontSize: '12px', marginBottom: '10px' }}
            />
        </span>
    );
};

const DjpHbo = ({ username, area_flag, region, branch, cluster_name, level }) => {
    const [outletId, setOutletId] = useState('');
    const [tanggalDjp, setTanggalDjp] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [hboList, setHboList] = useState([]);
    const [selectedHbo, setSelectedHbo] = useState('');
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalMultiple, setShowModalMultiple] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [inputData, setInputData] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const flagnya = "HBO";

    useEffect(() => {
        setLoading(true);

        axios.get(`${process.env.REACT_APP_SERVER}/data-ext-djp`, {
            params: { username, flagnya }
        })
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setLoading(false);
            });
    }, [username, refreshData]);

    const handleDelete = useCallback((id) => {
        Swal.fire({
            title: 'Peringatan Penghapusan',
            text: "Anda yakin akan menghapus data?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Tentu'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_SERVER}/deleteExtDjp`, {
                    params: { id }
                })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire('Deleted!', 'The data has been deleted.', 'success');
                            setRefreshData(!refreshData);
                        } else {
                            Swal.fire('Error', response.data.message, 'error');
                        }
                    })
                    .catch(error => {
                        console.error('There was an error deleting the data!', error);
                        Swal.fire('Error', 'There was an error deleting the data!', 'error');
                    });
            }
        });
    }, [refreshData]);

    const handleSelectRow = (id) => {
        setSelectedRows(prevSelectedRows => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter(rowId => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });
    };
    
    // Handle deleting multiple rows
    const handleDeleteMultiple = () => {
        Swal.fire({
            title: 'Peringatan Penghapusan',
            text: `Anda yakin ingin menghapus ${selectedRows.length} data terpilih?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Hapus'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_SERVER}/deleteMultipleExternalDjp`, {
                    data: { ids: selectedRows } // Send the selected row IDs
                })
                .then(response => {
                    if (response.data.success) {
                        Swal.fire('Deleted!', 'Data berhasil dihapus.', 'success');
                        setSelectedRows([]); // Clear selection
                        setRefreshData(!refreshData); // Refresh data
                    } else {
                        Swal.fire('Error', response.data.message, 'error');
                    }
                })
                .catch(error => {
                    console.error('There was an error deleting the data!', error);
                    Swal.fire('Error', 'There was an error deleting the data!', 'error');
                });
            }
        });
    };

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const columns = React.useMemo(
        () => [
            {
                Header: '#',
                Cell: ({ row }) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row.original.id_djp_ext)}
                      onChange={() => handleSelectRow(row.original.id_djp_ext)}
                    />
                  </div>
                ),
                disableSortBy: true,
                style: { textAlign: 'center' }
            },
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'ID', accessor: 'id_djp_ext', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'USERNAME', accessor: 'usernameTip', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'NAMA LENGKAP', accessor: 'namaTip', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'OUTLET ID', accessor: 'outlet_id', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'NAMA OUTLET', accessor: 'nama_outlet', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'DJP DATE', accessor: 'djp_date', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'AREA', accessor: 'area_flag', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'REGION', accessor: 'regional', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'BRANCH', accessor: 'branch', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'CLUSTER', accessor: 'cluster_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'CITY', accessor: 'kota', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'ACTION', Cell: ({ row: { original } }) => (
                <div style={{ textAlign: 'center', cursor:'pointer' }}>
                    <FaTrash onClick={() => handleDelete(original.id_djp_ext)} />
                </div>          
            )}
        ],
        [handleDelete, selectedRows]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        setGlobalFilter, // Tambahkan setGlobalFilter
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter, // Tambahkan useGlobalFilter
        useSortBy,
        usePagination
    );

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/getHboList`, {
            params: { username }
        })
        .then(response => {
            setHboList(response.data);
        })
        .catch(error => {
            console.error('There was an error fetching the TIP list!', error);
        });
    }, [username]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setOutletId(value);

        if (value.length > 0) {
            axios.get(`${process.env.REACT_APP_SERVER}/searchDjpOutlet`, { 
                params: { 
                    katakunci: value,
                    username,
                    area_flag, 
                    region, 
                    branch, 
                    cluster_name, 
                    level,
                } 
            }).then(response => {
                setSuggestions(response.data);
                setShowSuggestions(true);
            })
            .catch(error => {
                console.error('There was an error fetching the suggestions!', error);
            });
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setOutletId(suggestion.OUTLET_ID); 
        setShowSuggestions(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = { tanggalDjp, outletId, flagnya, username, tip: selectedHbo };

        axios.post(`${process.env.REACT_APP_SERVER}/insertDjpScheduleExt`, data)
            .then(response => {
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'DJP Created Successfully',
                        showConfirmButton: true,
                        timer: 1500
                    });
                    setOutletId('');
                    setTanggalDjp('');
                    setSelectedHbo('');
                    setRefreshData(!refreshData);
                    setShowModal(false);
                } else {
                    //alert('Error: ' + response.data.message);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message,
                    });
                }
            })
            .catch(error => {
                let errorMessage = 'An error occurred';
                // Check if error response exists and contains data and message
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
    
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
            }).finally(() => {
                setLoading(false); // Stop loading
            });
    };

    const handleSubmitMultiple = async (e) => {
        e.preventDefault();
        setLoading(true);

        const entries = inputData.split('\n');
        const outletIdPattern = /^[0-9]+$/;
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;    
        const uniqueEntries = new Set();
    
        entries.forEach(entry => {
            const [outletId, date] = entry.split(',');
            if (outletIdPattern.test(outletId) && datePattern.test(date)) {
                const key = `${outletId}|${date}|${username}|${flagnya}|${selectedHbo}`;
                uniqueEntries.add(key);
            }
        });
    
        const validData = Array.from(uniqueEntries).map(key => {
            const [outletId, date, username, flagnya, selectedHbo] = key.split('|');
            return { 
                outlet_id: outletId, 
                tanggal: date, 
                username: username,
                flagnya : flagnya,
                tim : selectedHbo 
            };
        });
        
        if (validData.length > 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER}/insertDjpScheduleExtMultiple`, validData);
                console.log('Data successfully sent:', response.data);
    
                Swal.fire({
                    icon: 'success',
                    title: `Successfully inserted ${response.data.affectedRows} rows.`,
                    showConfirmButton: true,
                    timer: 1500
                });
                setInputData('');
                setShowModalMultiple(false);
                setRefreshData(!refreshData);
    
            } catch (error) {
                console.error('Error sending data:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response ? error.response.data.message : 'An unexpected error occurred.',
                });
            }
        } else {
            console.log('No valid data to send');
            Swal.fire({
                icon: 'warning',
                title: 'No Valid Data',
                text: 'No valid data to send. Please check the input format.',
            });
        }

        setLoading(false);
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <header className="mb-4 d-flex justify-content-between align-items-center">
                <h5 className="text-danger">Create Household Buddies (HBO) DJP</h5>
                <div className="ml-auto">
                    <Button variant="danger" onClick={() => setShowModal(true)} style={{ fontSize: '12px', marginRight: '5px' }}>Add New</Button>
                    <Button variant="danger" onClick={() => setShowModalMultiple(true)} style={{ fontSize: '12px', marginRight: '5px' }}>Add Multiple</Button>
                    <Button variant="danger" onClick={handleDeleteMultiple} disabled={selectedRows.length === 0} style={{ fontSize: '12px' }}>Hapus Terpilih ({selectedRows.length})</Button>
                </div>
            </header>

            {/* Table Container */}
            <Container>
                <Row>
                    <Col>
                        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '10px' }}>
                                <thead className="custom-thead text-center">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                >
                                                    {column.render('Header')}
                                                    <span className="sort-arrow"></span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className="py-1 my-0">{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>
            {/* Modal for Creating Personal DJP */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '15px' }}>CREATE HOUSEHOLD BUDDIES DJP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Row className='pb-2'>
                                <Col xs={4}>
                                    <Form.Label className="d-flex justify-content-end">Tanggal</Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type="date"
                                        value={tanggalDjp}
                                        onChange={(e) => setTanggalDjp(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className='pb-2'>
                                <Col xs={4}>
                                    <Form.Label className="d-flex justify-content-end">HBO Name</Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Select
                                        size="sm"
                                        name="userNama"
                                        value={selectedHbo}
                                        onChange={(e) => setSelectedHbo(e.target.value)}
                                        required
                                        style={{ width: '90%' }}
                                    >
                                        <option value="" disabled>Pilih Buddies - HBO</option>
                                        {hboList.map((tip) => (
                                            <option key={tip.id_user_tip} value={tip.username}>{tip.username} - {tip.nama_lengkap}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='pb-2'>
                                <Col xs={4}>
                                    <Form.Label className="d-flex justify-content-end">Outlet ID</Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="outletId"
                                        placeholder=""
                                        style={{ width: '90%' }}
                                        value={outletId}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                        required
                                    />
                                    {showSuggestions && (
                                        <ListGroup style={{ position: 'absolute', zIndex: 1000, width: '90%' }}>
                                            {suggestions.map((suggestion, index) => (
                                                <ListGroup.Item
                                                    className="p-1"
                                                    key={index}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                    style={{ cursor: 'pointer', fontSize: '12px' }}
                                                >
                                                    {suggestion.OUTLET_ID} - {suggestion.NAMA_OUTLET}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </Col>
                            </Row>
                            <Row className='pb-2'>
                                <Col xs={4}>
                                    <Form.Label className="d-flex justify-content-end"></Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Button variant="danger" type="submit">Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>

            {/* Modal for Creating DJP Multiple */}
            <Modal show={showModalMultiple} onHide={() => setShowModalMultiple(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '15px'}}>CREATE HBO DJP - MULTIPLE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmitMultiple}>
                            <Row className='pb-2'>
                                <Col xs={12}>
                                    <Form.Select
                                        size="sm"
                                        className="mb-3"
                                        name="userNama"
                                        value={selectedHbo}
                                        onChange={(e) => setSelectedHbo(e.target.value)}
                                        required
                                        style={{ width: '100%' }}
                                    >
                                        <option value="" disabled>Pilih Buddies - HBO</option>
                                        {hboList.map((tip) => (
                                            <option key={tip.id_user_tip} value={tip.username}>{tip.username} - {tip.nama_lengkap}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col xs={12}>
                                    <textarea
                                        style={{ border: '1px solid #DDDDDD', width: '100%'}}
                                        rows="10"
                                        cols="50"
                                        value={inputData}
                                        onChange={(e) => setInputData(e.target.value)}
                                        placeholder="contoh : 1234567,2024-08-01"
                                    ></textarea>
                                </Col>
                                <Col xs={12} className="text-end">
                                    <Button variant="danger" type="submit">Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DjpHbo;
